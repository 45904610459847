import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import InfoWindow from "../../components/InfoWindow/InfoWindow";
import { getPhoneCode, registerNew } from "../../redux/actions/auth";
import Page from "./Page/Page";

const RegistrationNewPage = () => {
  const [ errMsg, setErrMsg ] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authResult = window.location.search;

  if (authResult.split("=")[1]) {
    const backUrl = authResult.split("=")[1];
    sessionStorage.setItem("backUrl", backUrl);
  }
  const [alertModal, setAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [passwordShown, setPasswordShown] = useState(false);
  const [code, setCode] = useState(false);
  const [readyForChange, setReadyForChange] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const [form, setForm] = useState({
    nickname: "",
    email: "",
    password: "",
    code: "",
    firstName: "",
    secondName: "",
    lastName: "",
    taxCode: "",
  });

  const [validForm, setValidForm] = useState({
    email: false,
    nickname: true,
    password: true,
    code: true,
    firstName: true,
    secondName: true,
    lastName: true,
    taxCode: true,
  });

  const resiveCode = async (event) => {
    try {
      event.preventDefault();
      setSeconds(60);
      setReadyForChange(true);
      let data={
        phone:form.phone,
        nickname:form.nickname,
        password:form.password
      }
      dispatch(getPhoneCode(data))
        .then((res) => {
          if (res && res.errors && res.errors.length > 0) {
            setErrMsg(res.errors);
            setReadyForChange(false);
          }else if(res && res.error){
            let data=[{
              msg:res.message,
              param:"phone",
            }]
            setErrMsg(data);
            setReadyForChange(false);
          }
          setCode(true);
        })
        .catch((e) => {
          console.log(e);
        });
    } 
    catch (e) {
      console.log(e);
    }
  };
  const handleClick = () => {
    navigate("/login");
  };

  const changeHandler = (event) => {
    const emailCheck =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const textCheck = /^[бвгґджзклмнпрстфхцчшщйаеєиіїоуюяь\'-`]+$/i;
    const passwordCheck = /^(?=.*\d)(?=.*?[a-z])(?=.*?[A-Z]).{6,30}$/;
    // const taxCodeCheck=/^[0-9]{10}$/;
    const codeCheck = /^[0-9]{6}$/;
    setErrMsg(null);

    switch (event.target.name) {
      case "email":
        if (code) {
          setReadyForChange(true);
        }
        setCode(false);
        if (emailCheck.test(String(event.target.value).toLowerCase())) {
          setValidForm({ ...validForm, [event.target.name]: true });
        } else {
          setValidForm({ ...validForm, [event.target.name]: false });
        }
        setForm({ ...form, [event.target.name]: event.target.value });
        break;
      case "name":
        // case 'secondName':
        // case 'lastName':
        const isValid = textCheck.test(
          String(event.target.value).toLowerCase()
        );
        setValidForm({ ...validForm, [event.target.name]: isValid });
        setForm({ ...form, [event.target.name]: event.target.value });
        break;
      case "password":
        if (passwordCheck.test(String(event.target.value))) {
          setValidForm({ ...validForm, [event.target.name]: true });
        } else {
          setValidForm({ ...validForm, [event.target.name]: false });
        }
        setForm({ ...form, [event.target.name]: event.target.value });
        break;
      // case 'taxCode':
      //     if( taxCodeCheck.test(String(event.target.value)) ){
      //         setValidForm({...validForm,[event.target.name]:true})
      //     }else{
      //         setValidForm({...validForm,[event.target.name]:false})
      //     };
      //     setForm({...form, [event.target.name]: event.target.value})
      //     break;
      case "code":
        if (codeCheck.test(String(event.target.value))) {
          setValidForm({ ...validForm, [event.target.name]: true });
        } else {
          setValidForm({ ...validForm, [event.target.name]: false });
        }
        setForm({ ...form, [event.target.name]: event.target.value });
        break;
      case "nickname":
        setForm({ ...form, [event.target.name]: event.target.value });
      case "phone":
        setForm({ ...form, [event.target.name]: event.target.value });
      default:
        break;
    }
  };
  const registerHandler = async (event) => {
    event.preventDefault();
    let data={
      phone:form.phone,
      code:form.code,
      deviceId:'',
      typeId:0,
      nickname:form.nickname,
      password:form.password,
      facebookToken:'',
      googleToken:'',
      appleToken:'',
      bankIdToken:'',
    }
    if(localStorage.getItem('FBLogSuccess')){
      data.facebookToken= JSON.parse(localStorage.getItem("FBLogSuccess")).accessToken;
    }
    if(localStorage.getItem('GoogleLogSuccess')){
      data.googleToken= JSON.parse(localStorage.getItem("GoogleLogSuccess")).credential;
    }
    dispatch(registerNew(data))
      .then((res) => {
        if (res && res.errors && res.errors.length > 0) {
          setErrMsg(res.errors);
          setReadyForChange(false);
        } else if(res.error)
        {
         setAlertModal(true)
         setAlertMessage('Щось пішло не так, спробуйте ще раз')

          setErrMsg(res.message)
        }else {
          navigate("/registration2/"+res.payload.id);
        }
      })
      .catch((e) => {});
  };
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  }, [seconds]);

  useEffect(() => {
    if(localStorage.getItem('FBLogSuccess') || localStorage.getItem('GoogleLogSuccess')){
      setAlertModal(true);
      setAlertMessage("Користувача не зареєстровано")
    }
  }, []);


  if (
    document.getElementsByClassName("sidebar") &&
    document.getElementsByClassName("sidebar")[0] &&
    document.getElementsByClassName("sidebar")[0].hidden !== true
  ) {
    document.getElementsByClassName("sidebar")[0].hidden = true;
  }
  return (
    <div className="authContainer">
      {alertModal && (
          <InfoWindow
            setModal={setAlertModal}
            message={alertMessage}
          ></InfoWindow>
        )}
      <form className="authForm" onSubmit={registerHandler}>
        <h1>
          Реєстрація
          <h4>Приєднуйтесь</h4>
        </h1>
        {
          <Page
            form={form}
            handleClick={handleClick}
            changeHandler={changeHandler}
            passwordShown={passwordShown}
            setPasswordShown={setPasswordShown}
            resiveCode={resiveCode}
            registerHandler={registerHandler}
            validForm={validForm}
            seconds={seconds}
            readyForChange={readyForChange}
            code={code}
            errMsg={errMsg}
          ></Page>
        }
      </form>
      <div className="rightSide">
        <img loading="lazy" src="images/RegistrationImage.svg" alt="Registration1 Not Found" />
        <div className="registrPage">ЗДОРОВА&nbsp;УКРАЇНА</div>
        <div className="registrPage">
          <span>ПРОГРАМА&nbsp;ПРЕЗИДЕНТА&nbsp;УКРАЇНИ</span>{" "}
        </div>
      </div>
    </div>
  );
};

export default RegistrationNewPage;
