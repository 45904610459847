import React, { useState, useEffect } from "react";
import PhoneMask from "../../../components/PhoneMask/PhoneMask";

const Page = (props) => {
  const [error, setError] = useState([]);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (props.errMsg && props.errMsg.errors && props.errMsg.errors[0]) {
      setError(props.errMsg.errors[0]);
      console.log(error);
    } else if (props.errMsg && props.errMsg[0]) {
      setError(props.errMsg[0]);
      console.log(error);
    } else {
      setError([]);
    }
  }, [props.errMsg]);

  const togglePasswordVisiblity = () => {
    props.setPasswordShown(props.passwordShown ? false : true);
  };

  return (
    <>
      <div
        className={
          error.param === "nickname" ? "apInputText apFilled" : "apInputText"
        }
      >
        <label htmlFor="nickname">Нікнейм</label>
        <input
          id="nickname"
          type="text"
          name="nickname"
          value={props.form.nickname}
          onChange={props.changeHandler}
        />
         <span className="subtitle">
         Нікнейм повинен містити від 3 до 30 латинських літер та цифр, без пробілу
        </span>
        {error.param === "nickname" && <span className="apError">{error.msg}</span>}
      </div>
      <div
        className={
          error.param === "password"
            ? "apInputTextIcon apFilled"
            : "apInputTextIcon"
        }
      >
        <label htmlFor="password">Пароль</label>
        <div>
          <input
            id="password"
            name="password"
            type={props.passwordShown ? "text" : "password"}
            onChange={props.changeHandler}
            value={props.form.password}
          />
          <svg onClick={togglePasswordVisiblity}>
            <use href="img/icon24MsgCircleDef.svg#icon24" />
          </svg>
        </div>
        <span className="subtitle">
          Пароль повинен містити великі, маленькі літери та цифри
        </span>
        {!props.validForm.password && (
          <span className="apError">Перевірте пароль</span>
        )}
        {error.param === "password" && (
          <span className="apError">{error.msg}</span>
        )}
      </div>
      <PhoneMask 
        className={error.param === "phone" ? "apInputText apFilled" : "apInputText"}  
        msg={error.param === "phone" && <span className="apError">{error.msg}</span>}
        onChange={props.changeHandler}
        value={props.form.phone}
      ></PhoneMask>

      <div className="codeBlock">
      <button
          style={{ marginTop: "6px" }}
          className="primaryButtonTextDefault"
          disabled={props.seconds > 0}
          onClick={props.resiveCode}
        >
          Отримати&nbsp;код
        </button>

        <div
          style={{ minWidth: "130px", maxWidth:'140px', marginRight: "10px" }}
          className={
            error.param === "code" ? "apInputText apFilled" : "apInputText"
          }
        >
          <label htmlFor="code">Код з телефону</label>
          <input
            id="code"
            type="text"
            name="code"
            onChange={props.changeHandler}
            value={props.form.code}
          />
          {error.param === "code" && (
            <span className="apError" style={{width:'300%'}}>{error.msg}</span>
          )}
        </div>
        {(props.readyForChange || props.seconds > 0) && <span style={{marginTop:'-20px'}}>Отримати код повторно через {props.seconds}</span> }
       
      </div>

      {/* <label className=''>
                    <span>Електронна пошта (логін)</span>
                    { props.readyForChange && props.seconds>0 && <span className='prompt'>Отримати код знову можна через: {props.seconds} сек.</span>}
                    <div className='btn'><input className={!props.validForm.email && props.form.email.length>0 ? 'inputInvalid btn txt-input':'btn txt-input'}  type="email" name="email" required onChange={props.changeHandler}  value={props.form.email}></input>
                    {props.code && !error.msg &&  props.seconds>0 ? <i className='icon ready-icon'></i> :<button className="txt-button-small" onClick={props.resiveCode} disabled={!props.validForm.email || props.seconds>0}>Отримати код </button>}</div>
                    {error.param === 'email' &&  <span className=''>{error.msg}</span>}
                </label >
                 */}
      {/* <label className=''>
                    <span>Код з пошти</span>ASasaSAzXzXasSazxaSCVDADASZXZCWESDAZCXADXCSADQWSADZCZSADWQESADZCZXDSFADSSDAWSADXZCXZASDASDSAZXCZXCWQEDSAAVDASDXZCWQEZXCDASWQEASDZXCZXCSADASQWEXCZZASDQWEXZCASDWSXSWSXSWASDZXCSADQSASAZXCASSADZXCSADASZXCASDQWSZCSAWQSADQWASDSDAWQEWEQDFSWEQASDWQEDSFXV
                    <input className={!props.validForm.code?'inputInvalid btn txt-input':'btn txt-input'} disabled={!props.code} type="text" name="code" required onChange={props.changeHandler}  value={props.form.code}/>
                    {!props.validForm.code && <span className=''>Перевірте правильність коду</span>}
                    {error.param === 'code' &&  <span className=''>{error.msg}</span>}
                </label> */}
      <div className="" style={{ marginBottom: "15px" }}>
        <input
          type="checkbox"
          id="agree"
          onClick={() => setChecked(!checked)}
        />
        <label htmlFor="agree">
          {" "}
          Даю згоду на{" "}
          <a
            href="https://ap.sportforall.gov.ua/info/iSdIifLP"
            target="_blank"
          >
            {" "}
            передачу та обробку персональних даних
          </a>
        </label>
      </div>

      <button
        className={
          checked &&props.form.code?.length===6 && props.form.phone?.length>6 && props.form.nickname?.length>4&& props.form.password?.length>7 && props.code ? "primaryButtonTextDefault" : "primaryButtonTextDisabled"
        }
        type="submit"
        disabled={!(checked && props.form.code?.length===6 && props.form.phone?.length>6 &&props.form.nickname?.length>4 && props.form.password?.length>7&& props.code)}
        onClick={props.registerHandler}
      >
        Перейти далі
      </button>
      {/* <button type="submit" className='' onSubmit={props.registerHandler} disabled={!props.validForm.taxCode || !props.form.taxCode.length>0 || !props.validForm.password || !props.form.password.length>0 || !props.validForm.code || !props.form.code.length>0 || error.param} >Зареєструватися</button>  */}
      <span style={{textAlign: 'center'}}>
        Вже зареєстровані?
        <a className="" onClick={props.handleClick}>
          {" "}
          Увійти
        </a>
      </span>
    </>
  );
};

export default Page;
