import React from "react";
// import svgString from './id-gov-ua-diia.svg'; // Assuming the SVG is in the same directory

const svgString = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   viewBox="0 0 1336.82 360"
   version="1.1"
   id="svg900"
   sodipodi:docname="id-gov-ua-diia.svg"
   inkscape:version="0.92.3 (2405546, 2018-03-11)">
  <metadata
     id="metadata904">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <sodipodi:namedview
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1"
     objecttolerance="10"
     gridtolerance="10"
     guidetolerance="10"
     inkscape:pageopacity="0"
     inkscape:pageshadow="2"
     inkscape:window-width="1920"
     inkscape:window-height="1018"
     id="namedview902"
     showgrid="false"
     inkscape:zoom="0.55130835"
     inkscape:cx="930.1749"
     inkscape:cy="-29.775943"
     inkscape:window-x="-8"
     inkscape:window-y="-8"
     inkscape:window-maximized="1"
     inkscape:current-layer="svg900" />
  <defs
     id="defs878">
    <style
       id="style876">.cls-1{fill:#fff;}</style>
  </defs>
  <title
     id="title880">id.gov.ua</title>
  <g
     id="Шар_2"
     data-name="Шар 2">
    <g
       id="Layer_1"
       data-name="Layer 1">
      <rect
         x="254"
         width="720"
         height="360"
         rx="180"
         id="rect882" />
      <path
         class="cls-1"
         d="M614,260a80,80,0,1,1,80-80A80.09,80.09,0,0,1,614,260Zm0-130a50,50,0,1,0,50,50A50.06,50.06,0,0,0,614,130Z"
         id="path884" />
      <path
         d="M1093,260a80.09,80.09,0,0,1-80-80V115a15,15,0,0,1,30,0v65a50,50,0,0,0,100,0V115a15,15,0,0,1,30,0v65A80.09,80.09,0,0,1,1093,260Z"
         id="path886" />
      <path
         class="cls-1"
         d="M785,260a15,15,0,0,1-13.52-8.51l-60-125a15,15,0,0,1,27-13L785,210.34l46.48-96.83a15,15,0,1,1,27,13l-60,125A15,15,0,0,1,785,260Z"
         id="path888" />
      <path
         d="M1321.83,260a15,15,0,0,1-13.54-8.51l-46.47-96.83-46.48,96.83a15,15,0,0,1-27-13l60-125a15,15,0,0,1,27,0l60,125A15,15,0,0,1,1321.83,260Z"
         id="path890" />
      <path
         class="cls-1"
         d="M432,260a80,80,0,1,1,56.57-136.57,15,15,0,1,1-21.21,21.21A50,50,0,1,0,479.7,195H432a15,15,0,0,1,0-30h65a15,15,0,0,1,15,15A80.09,80.09,0,0,1,432,260Z"
         id="path892" />
      <path
         d="M141,100H76a15,15,0,0,0-15,15V245a15,15,0,0,0,15,15h65a80,80,0,0,0,0-160Zm0,130H91V130h50a50,50,0,0,1,0,100Z"
         id="path894" />
      <path
         d="M15,100A15,15,0,0,0,0,115V245a15,15,0,0,0,30,0V115A15,15,0,0,0,15,100Z"
         id="path896" />
    </g>
  </g>
  <path
     style="stroke:#000000;stroke-width:9.27522087"
     inkscape:connector-curvature="0"
     id="path1562"
     d="m 1215.9425,198.33149 h 87.3394" />
  <path
     style="stroke:#000000;stroke-width:9.27522087"
     inkscape:connector-curvature="0"
     id="path1562-6"
     d="m 1217.1238,207.05024 h 87.3394" />
</svg>`;


const DiaProvider = ()=>{
  const kepClick = () => {
    let state = '';
    function uuidv4() {
      return (state = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
      ));
    }
    uuidv4();
    window.location = `https://test.id.gov.ua/?response_type=code&client_id=e0935d5d8e44767d7b8d48bb9db0a170&auth_type=dig_sign,bank_id,diia_id&state=${state}&redirect_uri=https://ap.sportforall.gov.ua/login`;
    localStorage.setItem('state', state);
  };

    return(<>
        <button
          className="btn-primary text-btn-primary buttonDia"
          style={{ marginTop: '0' }}
          type="button"
          onClick={kepClick}
        >
          <div dangerouslySetInnerHTML={{ __html: svgString }} />
        </button>
    </>)
}

export default DiaProvider;